
import ABAColor from "../assets/img/ABAColor.png";
import felabaLogo from "../assets/img/felabaLogo.png";
import background from "../assets/img/background.jpeg";
import firmaRR from "../assets/img/SignRR.png";
import firmaGT from "../assets/img/SignGT.png";
import firmaLU from "../assets/img/SignLU.jpg";
import QRCode from "react-qr-code";

const CerticateCOPLAFT2024 = (props) => {
  const ConferenceLogo = require(`../assets/img/${props.conference.conferecenCodeName}.png`);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "1200px",
        height: "800px",
        paddingLeft: "44px",
        paddingTop: "48px",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          width: "1112px",
          height: "704px",
          zIndex: 100,
        }}
      >
        <div className="formgrid grid">
          <div className="col-12 md:col-2">
            <div
              style={{
                marginLeft: "10px",
                marginTop:"10px",
                marginBottom:"10px",
                width: "90px",
                height: "90px",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%", }}
                viewBox={`0 0 256 256`}
                value={
                  process.env.REACT_APP_URL +
                  "/Certificates/Conferences/Sign/" +
                  props.ordenId +
                  "-" +
                  props.user.id +
                  "-" +
                  props.user.name +
                  "_" +
                  props.user.lastName +
                  ".pdf"
                }
              />
            </div>
          </div>
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-2"></div>
          <div
            className="col-12 md:col-2"
            style={{
              marginTop: "40px",
            }}
          >
            <img
              src={ABAColor}
              alt="Sample"
              className="card-image"
              width={35}
            />
            <img
              src={felabaLogo}
              alt="Sample"
              className="card-image"
              width={90}
            />
          </div>
          <div
            className="col-12 md:col-12"
            style={{
              marginLeft: "390px",
              marginTop: "0px",
              marginBottom: "10px",
            }}
          >
            <img
              src={ConferenceLogo}
              alt="Sample"
              className="card-image"
              width={310}
            />
          </div>
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-8">
            <p
              style={{
                color: "#182D5D",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
                marginBottom: "30px",
              }}
            >
              La Federación Latinoamericana de Bancos y la Asociación de Bancos
              Múltiples de la República Dominicana Inc., reconocen con este
              certificado a:
            </p>
          </div>
          <div className="col-12 md:col-2"></div>
          <div
            className="col-12 md:col-12"
            style={{
              textAlign: "center",
              color: "#182D5D",
              fontWeight: "800",
              width: "100%",
            }}
          >
            <span style={{ fontSize: "32px" }}>
              {props.user.name} {props.user.lastName}
            </span>
          </div>
          <div
            className="col-12 md:col-12"
            style={{
              borderBottom: "2px solid",
              width: "90%",
              marginLeft: "5%",
            }}
          ></div>
          <div
            className="col-12 md:col-12"
            style={{ textAlign: "center", width: "80%", marginLeft: "10%" }}
          >
            <p
              style={{
                color: "#182D5D",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Por su participación en el 3er Congreso Internacional de
              Prevención de Lavado de Activos y Financiamiento del Terrorismo,
              celebrado en la ciudad de Santo Domingo, República Dominicana.
            </p>
            <p
              style={{
                color: "#182D5D",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "30px",
              }}
            >
              Durante los días 3 y 4 de octubre de 2024
            </p>
          </div>
          <div className="col-12 md:col-4">
            <p
              style={{
                color: "#182D5D",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "15px",
                marginLeft: "50px",
              }}
            >
              <img
                src={firmaRR}
                alt="Sample"
                className="card-image"
                width={250}
                style={{ borderBottom: "2px solid" }}
              />
              <br />
              Rosanna Ruiz
              <br />
              Presidente Ejecutiva <br />
              <span style={{ fontSize: "16px" }}>
                Asociación de Bancos Múltiples de la República Dominicana Inc.
              </span>
            </p>
          </div>
          <div className="col-12 md:col-4">
            <p
              style={{
                color: "#182D5D",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "0px",
                marginLeft: "50px",
              }}
            >
              <img
                src={firmaLU}
                alt="Sample"
                className="card-image"
                width={180}
                style={{ borderBottom: "2px solid" }}
              />
              <br />
              Lidia Ureña <br /> Presidente
              <br />
              <span style={{ fontSize: "16px" }}>
                {" "}
                Comité de Prevención de Lavado de Activos y Financiamiento del
                Terrorismo
              </span>
            </p>
          </div>
          <div className="col-12 md:col-4">
            <p
              style={{
                color: "#182D5D",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "15px",
                marginLeft: "40px",
              }}
            >
              <img
                src={firmaGT}
                alt="Sample"
                className="card-image"
                width={200}
                style={{ borderBottom: "2px solid" }}
              />
              <br />
              Giorgio Trettenero
              <br /> Secretario General <br />
              <span style={{ fontSize: "16px" }}>
                Federación Latinoamericana de Bancos
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CerticateCOPLAFT2024;
