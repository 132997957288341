import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

import {
  GetStatus,
  ActiveStatus,
  InactiveStatus,
} from "../../../store/ordenstatus-store/ordenstatus-actions";
import Loading from "../../../components/UI/Loading";

const OrdenStatusDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.ordenStatus.status);
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    dispatch(GetStatus(param.statusId, auth.UserInfo.email));
  }, [param.statusId, auth, dispatch]);

  const activeHandler = () => {
    dispatch(ActiveStatus(param.statusId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactiveStatus(param.statusId, auth.UserInfo.email));
  };
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("status.active")) {
    activeSuspend = (
      <Button
        rounded
        text
        raised
        style={{ backgroundColor: "#fff", marginLeft: "1%" }}
        label="Activar"
        className="p-button-success"
        onClick={activeHandler}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.status === "pending"}
      />
    );
  }
  if (status.status) {
    if (auth.roles !== undefined && auth.roles.includes("status.inactive")) {
      activeSuspend = (
        <Button
          rounded
          text
          raised
          style={{ backgroundColor: "#fff", marginLeft: "1%" }}
          label="Suspender"
          className="p-button-warning"
          onClick={inactiveHandler}
          icon={
            notification && notification.status === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-times"
          }
          disabled={notification && notification.status === "pending"}
        />
      );
    }
  }
  return (
    <>
      {(status.id === undefined || status.id === 0) && <Loading />}
      {status.id !== undefined && status.id > 0 && (
        <Card
          style={{
            backgroundColor: "#183462",
            marginLeft: "1%",
            marginTop: "8px",
            boxShadow: "none",
            width: "99%",
            border: "solid #326fd1",
          }}
        >
          <div
            className="user-info text-center"
            style={{ borderBottom: "solid #fff", paddingBottom: "4px" }}
          >
            <h2
              id="header"
              className="mb-2"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {status.description.toUpperCase()}
            </h2>
            <span
              className="badge bg-label-secondary"
              style={{
                fontSize: "large",
                color: "lightskyblue",
                fontWeight: "bold",
              }}
            >
              Estado orden
            </span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Color:{" "}
              </label>
              <div
                style={{
                  backgroundColor: status.color,
                  color: status.color,
                  border: "solid 1px black",
                }}
              >
                status.color
              </div>
            </div>
            <div className="field col-12 md:col-12 mt-3">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/orden/statues");
                }}
              />
              {auth.roles !== undefined &&
                auth.roles.includes("status.edit") && (
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Editar"
                    editor="pi pi-file-edit"
                    className="p-button-primary"
                    onClick={() => {
                      navigate(`/orden/status/edit/${param.statusId}`);
                    }}
                  />
                )}
              {activeSuspend}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default OrdenStatusDetail;
