import { departmentActions } from "./department-slice";
import { uiActions } from "../ui-slice";

export const GetDepartments = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cargando inforamción",
        message: "Se esta solicitando la información al servidor.",
      })
    );
    const GetDepartmentsInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetDepartmentsInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartments({
            departments: response.data.map((department) => {
              return {
                ...department,
                status: department.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartments({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetDepartment = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando departamento",
        message: "Solicitando departamento al servidor.",
      })
    );
    const getDepartmentInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el departamento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDepartmentInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartment({
            department: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartment({
            department: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el departamento",
        })
      );
    }
  };
};
export const UpdateDepartment = (department, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando departamento",
        message: "Solicitando Actualización del departamento al servidor.",
      })
    );
    const updateDepartmentInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: department.id,
            Name: department.name,
            Description: department.description,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el departamento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateDepartmentInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartment({
            department: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartment({
            department: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el departamento",
        })
      );
    }
  };
};
export const CreateDepartment = (department, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando departamento",
        message: "Solicitando Creación del departamento al servidor.",
      })
    );
    const createDepartmentInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Name: department.name,
            Description: department.description,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el departamento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createDepartmentInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartment({
            department: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartment({
            department: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el departamento",
        })
      );
    }
  };
};
export const ActiveDepartment = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando departamento",
        message: "Solicitando activación del departamento al servidor.",
      })
    );
    const activeDepartmentInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el departamento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeDepartmentInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartment({
            department: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartment({
            department: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el departamento",
        })
      );
    }
  };
};
export const InactiveDepartment = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando departamento",
        message: "Solicitando activación del departamento al servidor.",
      })
    );
    const inactiveDepartmentInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el departamento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveDepartmentInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartment({
            department: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartment({
            department: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el departamento",
        })
      );
    }
  };
};
