import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPaymentMethod,
  ActivePaymentMethod,
  InactivePaymentMethod,
} from "../../../store/paymentmethod-store/paymentmethod-actions";
import { useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import Loading from "../../../components/UI/Loading";
const PaymentMethodDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentMethod = useSelector(
    (state) => state.paymentMethod.paymentMethod
  );
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    dispatch(GetPaymentMethod(param.paymentMethodId, auth.UserInfo.email));
  }, [param.paymentMethodId, auth, dispatch]);

  const activeHandler = () => {
    dispatch(ActivePaymentMethod(param.paymentMethodId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactivePaymentMethod(param.paymentMethodId, auth.UserInfo.email));
  };
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("paymentmethod.active")) {
    activeSuspend = (
      <Button
        rounded
        text
        raised
        style={{ backgroundColor: "#fff", marginLeft: "1%" }}
        label="Activar"
        className="p-button-success"
        onClick={activeHandler}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.status === "pending"}
      />
    );
  }
  if (paymentMethod.status) {
    if (
      auth.roles !== undefined &&
      auth.roles.includes("paymentmethod.inactive")
    ) {
      activeSuspend = (
        <Button
          rounded
          text
          raised
          style={{ backgroundColor: "#fff", marginLeft: "1%" }}
          label="Suspender"
          className="p-button-warning"
          onClick={inactiveHandler}
          icon={
            notification && notification.status === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-times"
          }
          disabled={notification && notification.status === "pending"}
        />
      );
    }
  }

  return (
    <>
      {(paymentMethod.id === undefined || paymentMethod.id === 0) && (
        <Loading />
      )}
      {paymentMethod.id !== undefined && paymentMethod.id > 0 && (
        <Card
          style={{
            backgroundColor: "#183462",
            marginLeft: "1%",
            marginTop: "8px",
            boxShadow: "none",
            width: "99%",
            border: "solid #326fd1",
          }}
        >
          <div
            className="user-info text-center"
            style={{ borderBottom: "solid #fff", paddingBottom: "4px" }}
          >
            <h2
              id="header"
              className="mb-2"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {paymentMethod.name.toUpperCase()}
            </h2>
            <span
              className="badge bg-label-secondary"
              style={{
                fontSize: "large",
                color: "lightskyblue",
                fontWeight: "bold",
              }}
            >
              Método de pago
            </span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 md:col-3">
              <>
                <label style={{ color: "#fff", fontSize: "large" }}>
                  Descripción:{" "}
                </label>
                <label
                  style={{
                    color: "lightskyblue",
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {" "}
                  {paymentMethod.description}
                </label>
              </>
            </div>
            <div className="field col-12 md:col-12 mt-3">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/orden/paymentmethods");
                }}
              />
              {auth.roles !== undefined &&
                auth.roles.includes("paymentmethod.edit") && (
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Editar"
                    className="p-button-primary"
                    icon="pi pi-file-edit"
                    onClick={() => {
                      navigate(
                        `/orden/paymentmethod/edit/${param.paymentMethodId}`
                      );
                    }}
                  />
                )}
              {activeSuspend}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default PaymentMethodDetail;
