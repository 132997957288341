import { courseActions } from "./course-slice";
import { uiActions } from "../ui-slice";

export const GetCourses = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Cursos",
        message: "Solicitando Cursos al servidor.",
      })
    );
    const getCoursesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/getCourses`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los cursos");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCoursesInfo();
      if (response.result.errorCode === "OK") {
   
        dispatch(
          courseActions.setCourses({
            courses: response.data.map((course) => {
              return {
                ...course,
                status: course.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourses({
            courses: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los cursos",
        })
      );
    }
  };
};
export const GetCourse = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Curso",
        message: "Solicitando Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/getCourseById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: `Curso ${response.data.topic} Cargado`,
            message: response.result.message,
          })
        );
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el curso",
        })
      );
    }
  };
};
export const ActiveCourse = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando Curso",
        message: "Solicitando Activación del Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/activeCourse`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el curso",
        })
      );
    }
  };
};
export const InactiveCourse = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando Curso",
        message: "Solicitando Inactivanción del Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/inactiveCourse`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: {
              id: response.data.id,
              speaker: response.data.speaker,
              type: response.data.type,
              topic: response.data.topic,
              description: response.data.description,
              format: response.data.format,
              monday: response.data.monday,
              tuesday: response.data.tuesday,
              wednesday: response.data.wednesday,
              thursday: response.data.thursday,
              friday: response.data.friday,
              saturday: response.data.sunday,
              sunday: response.data.sunday,
              duration: response.data.duration,
              price: response.data.price,
              discount: response.data.discount,
              startDate: response.data.startDate,
              endDate: response.data.endDate,
              startTime: response.data.startTime,
              endTime: response.data.endTime,
              createdAt: response.data.createdAt,
              creationUser: response.data.creationUser,
              updatedAt: response.data.updatedAt,
              updateUser: response.data.updateUser,
              status: response.data.status,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el curso",
        })
      );
    }
  };
};
export const UpadateCourse = (course,  email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Curso",
        message: "Solicitando Actualización del Curso al servidor.",
      })
    );

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/updateCourse`,
        {
          method: "POST",
          body: JSON.stringify({
            ...course,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el curso",
        })
      );
    }
  };
};
export const CreateCourse = (course,  email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Curso",
        message: "Solicitando Actualización del Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/addCourse`,
        {
          method: "POST",
          body: JSON.stringify({
            ...course,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Error") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el curso",
        })
      );
    }
  };
};
export const UploadMediaCourse = (course,  email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Subiendo archivos del Curso",
        message: "Solicitando Actualización del Curso al servidor.",
      })
    );
    let formData = new FormData();
    formData.append("image", course.image);
    formData.append("brochure", course.brochure);
    formData.append("zoomUrl", course.zoomUrl);
    formData.append("id", course.id);

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/media`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Error") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el curso",
        })
      );
    }
  };
};

export const GetCoursesActive = (email) => {
  return async (dispatch) => {
    const getCoursesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}Course/getallActive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los cursos");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCoursesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourses({
            courses: response.data.map((course) => {
              return {
                ...course,
                status: course.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        // dispatch(
        //   uiActions.showNotification({
        //     status: "success",
        //     title: "Datos Cargados",
        //     message: response.result.message,
        //   })
        // );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourses({
            courses: [],
          })
        );
      }
    } catch (error) {}
  };
};
export const GetCourseSelected = (id, email) => {
  return async (dispatch) => {
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}Course/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: {
              id: response.data.id,
              speaker: response.data.speaker,
              type: response.data.type,
              topic: response.data.topic,
              description: response.data.description,
              format: response.data.format,
              monday: response.data.monday,
              tuesday: response.data.tuesday,
              wednesday: response.data.wednesday,
              thursday: response.data.thursday,
              friday: response.data.friday,
              saturday: response.data.sunday,
              sunday: response.data.sunday,
              duration: response.data.duration,
              price: response.data.price,
              discount: response.data.discount,
              startDate: response.data.startDate,
              endDate: response.data.endDate,
              startTime: response.data.startTime,
              endTime: response.data.endTime,
              createdAt: response.data.createdAt,
              creationUser: response.data.creationUser,
              updatedAt: response.data.updatedAt,
              updateUser: response.data.updateUser,
              status: response.data.status,
            },
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
      }
    } catch (error) {}
  };
};
