import Attended from "../../pages/Admin/Users/Attended";
import Loading from "../UI/Loading";
import YesNoModal from "../YesNoModal";

const AttendedComponent = (props) => {

  return (
    <>
      {(props.users === undefined || props.users.length === 0) && <Loading />}
      {props.users !== undefined && props.users.length > 0 && (
        <YesNoModal
          content={
            <>
              <Attended users={props.users} />
            </>
          }
          ExternalAction={false}
          actionButtonLabel="Enviar Certificados"
          actionIcon="pi pi-envelope"
          actionSeverity="primary"
          visible={props.visibleAttended}
          setVisible={props.setVisibleAttended}
          showButton={false}
        />
      )}
    </>
  );
};
export default AttendedComponent;
