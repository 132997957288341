import { useDispatch, useSelector } from "react-redux";
import YesNoModal from "../YesNoModal";
import { useEffect, useState } from "react";
import Loading from "../UI/Loading";
import { Dropdown } from "primereact/dropdown";
import { GetPaymentMethods } from "../../store/paymentmethod-store/paymentmethod-actions";
import { UpdatePaymenMethod } from "../../store/orden-store/orden-actions";

const PaymentMethodComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const paymentMethodList = useSelector(
    (state) => state.paymentMethod.paymentMethods
  );
  const [refresh, setRefresh] = useState(false);
  const [selectedPaymentMethod, setPaymentMethod] = useState({
    code: 0,
    name: "",
  });

  useEffect(() => {
    if (props.conference.id > 0 && paymentMethodList.length === 0) {
      dispatch(GetPaymentMethods(auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, props.conference.id, dispatch, paymentMethodList]);

  let paymentMethods = [];
  if (paymentMethodList.length > 0) {
    paymentMethods = paymentMethodList.filter(
      (paymentMethod) => paymentMethod.status === "Activo"
    );
    paymentMethods = paymentMethods.map((paymentMethod) => {
      return {
        code: paymentMethod.id,
        name: paymentMethod.name,
      };
    });
  }
  const headerPaymentMethod = (
    <>
      {props.updateOrden.paymentMethod !== undefined && (
        <span style={{ fontSize: "x-large" }}>
          Método actual :
          <span style={{ color: "#eec137" }}>
            {props.updateOrden.paymentMethod.name}
          </span>
        </span>
      )}
    </>
  );
  const contentPaymentMethod = (
    <>
      {refresh === true && (
        <>
          <Loading color="#113D4D" />
        </>
      )}
      {refresh === false && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <label
              htmlFor="paymentMethod"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Método de pago:
            </label>
            {props.updateOrden.paymentMethod !== undefined && (
              <Dropdown
                id="paymentMethod"
                value={selectedPaymentMethod}
                onChange={(e) =>
                  setPaymentMethod({ code: e.value.code, name: e.value.name })
                }
                options={paymentMethods.filter((paymentMethod) => {
                  return (
                    paymentMethod.code !== props.updateOrden.paymentMethod.id
                  );
                })}
                optionLabel="name"
                filter
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  height: "50px",
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
  const changePaymentMethodHandler = () => {
    dispatch(
      UpdatePaymenMethod(
        props.updateOrden.id,
        selectedPaymentMethod.code,
        auth.UserInfo.email
      )
    );
    setPaymentMethod({
      code: 0,
      name: "",
    });
    setTimeout(function () {
      window.location.reload();
    }, 1500);
    setRefresh(true);
  };
  return (
    <>
      <YesNoModal
        header={headerPaymentMethod}
        content={contentPaymentMethod}
        visible={props.visiblePaymentMethod}
        setVisible={props.setVisiblePaymentMethod}
        yesHandler={changePaymentMethodHandler}
        showButton={selectedPaymentMethod.code !== 0}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default PaymentMethodComponent;
