import { useState } from "react";
import YesNoModal from "../YesNoModal";
import Loading from "../UI/Loading";
import { FileUpload } from "primereact/fileupload";
import { useDispatch, useSelector } from "react-redux";
import { SendBill } from "../../store/orden-store/orden-actions";

const SendBiillComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [billFile, setBillFile] = useState(null);

  const onSelectHandler = (file) => {
    setBillFile(file.files[0]);
  };
  const handleSendBill = async () => {
    const bill = {
      orderNumber: props.orden.id,
      user: props.orden.user,
    };
    dispatch(SendBill(bill, billFile, auth.UserInfo.email));
    setTimeout(function () {
      props.setVisibleSendBill(false);
      setRefresh(false);
    }, 1500);
    setRefresh(true);
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-upload",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined ",
  };
  const fileUpload = (
    <FileUpload
      style={{ width: "100%", borderRadius: "12px" }}
      name="fileUpload"
      accept=".pdf"
      customUpload={true}
      onSelect={onSelectHandler}
      mode="advanced"
      chooseOptions={chooseOptions}
      uploadOptions={uploadOptions}
      cancelOptions={cancelOptions}
      emptyTemplate={<p className="m-0">Seleccione factura a enviar.</p>}
    />
  );

  const headerSendBill = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Detalle de la orden:{" "}
        <span style={{ color: "#F9C02D" }}>{props.orden.id}</span>
      </span>
    </>
  );
  const contentSendBill = (
    <>
      <>
        {refresh === true && (
          <>
            <Loading color="#113D4D" />
          </>
        )}
        {refresh === false && (
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <span style={{ color: "#35c4dc" }}> Enviar la factura a:</span>{" "}
              <br />
              <span>
                {props.orden.user !== undefined ? props.orden.user.name : ""}{" "}
                {props.orden.user !== undefined
                  ? props.orden.user.lastName
                  : ""}
              </span>{" "}
              <br />
              <span>
                {props.orden.user !== undefined ? props.orden.user.email : ""}
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <label
                htmlFor="Factura"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Seleccionar Factura:{fileUpload}
              </label>
            </div>
          </div>
        )}
      </>
    </>
  );

  return (
    <>
      <YesNoModal
        className="p-"
        header={headerSendBill}
        content={contentSendBill}
        visible={props.visibleSendBill}
        setVisible={props.setVisibleSendBill}
        yesHandler={handleSendBill}
        showButton={billFile !== null}
        yesButtonLabel="Enviar"
      />
    </>
  );
};
export default SendBiillComponent;
