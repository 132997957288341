import { chargeActions } from "./charge-slice";
import { uiActions } from "../ui-slice";

export const GetCharges = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cargando inforamción",
        message: "Se esta solicitando la información al servidor.",
      })
    );
    const GetChargesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetChargesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharges({
            charges: response.data.map((charge) => {
              return {
                ...charge,
                status: charge.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharges({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetCharge = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando cargo",
        message: "Solicitando cargo al servidor.",
      })
    );
    const getChargeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el cargo");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getChargeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharge({
            charge: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharge({
            charge: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el cargo",
        })
      );
    }
  };
};
export const UpdateCharge = (charge, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando cargo",
        message: "Solicitando Actualización del cargo al servidor.",
      })
    );
    const updateChargeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: charge.id,
            Name: charge.name,
            Description: charge.description,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el cargo");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateChargeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharge({
            charge: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharge({
            charge: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el cargo",
        })
      );
    }
  };
};
export const CreateCharge = (charge, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando cargo",
        message: "Solicitando Creación del cargo al servidor.",
      })
    );
    const createChargeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Name: charge.name,
            Description: charge.description,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el cargo");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createChargeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharge({
            charge: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharge({
            charge: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el cargo",
        })
      );
    }
  };
};
export const ActiveCharge = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando cargo",
        message: "Solicitando activación del cargo al servidor.",
      })
    );
    const activeChargeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el cargo");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeChargeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharge({
            charge: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharge({
            charge: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el cargo",
        })
      );
    }
  };
};
export const InactiveCharge = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando cargo",
        message: "Solicitando activación del cargo al servidor.",
      })
    );
    const inactiveChargeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el cargo");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveChargeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharge({
            charge: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharge({
            charge: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el cargo",
        })
      );
    }
  };
};
