import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
  course: {
    type: "",
    topic: "",
    description: "",
    format: "",
    duration: 0,
    price: 0,
    discount: 0,
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
    startTime: "",
    endTime: "",
    status: false,
    courseDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    courseSpeakers: [],
    image: null,
    brochure: null,
    zoomUrl: "",
  },

  courseValidation: {
    isValid: true,
    courseSpeakers: {
      isValid: true,
      errorMessage: "Debe Seleccionar al menos un capacitador",
    },
    courseDays: {
      isValid: true,
      errorMessage: "Debe seleccionar al menos un día",
    },
    type: {
      isValid: true,
      errorMessage: "El campo Tipo de Curso no debe estar vacio",
    },
    topic: {
      isValid: true,
      errorMessage: "El campo Tema no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo Descripción no debe estar vacio",
    },
    format: {
      isValid: true,
      errorMessage: "El campo Formato no debe estar vacio",
    },

    duration: {
      isValid: true,
      errorMessage: "El campo Duración no debe estar vacio",
    },
    price: {
      isValid: true,
      errorMessage: "El campo Precio no debe estar vacio",
    },
    startDate: {
      isValid: true,
      errorMessage: "El Fecha inicio Docente no debe estar vacio",
    },
    endDate: {
      isValid: true,
      errorMessage: "El campo Fecha Fin no debe estar vacio",
    },
    startTime: {
      isValid: true,
      errorMessage: "El campo Hora de inicio no debe estar vacio",
    },
    endTime: {
      isValid: true,
      errorMessage: "El campo Hora de fin no debe estar vacio",
    },
  },
};

const courseSlice = createSlice({
  name: "Course",
  initialState,
  reducers: {
    restartState(state) {
      state.course = {};
      state.courses = [];
    },
    setCourse(state, actions) {
      state.course = actions.payload.course;
      state.days = [];
    },
    setCourses(state, actions) {
      state.course = {};
      state.courses = actions.payload.courses;
      state.days = [];
    },
    modifyPropertyValue(state, actions) {
      state.course[actions.payload.id] = actions.payload.value;
    },
    setDays(state, actions) {
      state.course.courseDays[actions.payload.day] =
        !state.course.courseDays[actions.payload.day];
      if (
        !state.course.courseDays.monday &&
        !state.course.courseDays.tuesday &&
        !state.course.courseDays.wednesday &&
        !state.course.courseDays.thursday &&
        !state.course.courseDays.friday &&
        !state.course.courseDays.saturday &&
        !state.course.courseDays.sunday
      ) {
        state.courseValidation.courseDays.isValid = false;
      }
    },
    setSpeaker(state, actions) {
      state.course.courseSpeakers.push(actions.payload.speaker);
      if (state.course.courseSpeakers.length >= 1) {
        state.courseValidation.courseSpeakers.isValid = true;
      }
    },
    removeSpeake(state, actions) {
      state.course.courseSpeakers = state.courseSpeakers.filter(
        (speaker) => speaker !== actions.payload.speaker
      );
      if (state.course.courseSpeakers.length < 1) {
        state.courseValidation.courseSpeakers.isValid = false;
      }
    },
    setIsValid(state, actions) {
      state.courseValidation = {
        courseSpeakers: {
          ...state.courseValidation.courseSpeakers,
          isValid: actions.payload.courseSpeakers.length > 0,
        },
        type: {
          ...state.courseValidation.type,
          isValid: actions.payload.type !== "",
        },
        topic: {
          ...state.courseValidation.topic,
          isValid: actions.payload.topic !== "",
        },
        description: {
          ...state.courseValidation.description,
          isValid: actions.payload.description !== "",
        },
        format: {
          ...state.courseValidation.format,
          isValid: actions.payload.format !== "",
        },
        courseDays: {
          ...state.courseValidation.courseDays,
          isValid:
            state.course.courseDays.monday ||
            state.course.courseDays.tuesday ||
            state.course.courseDays.wednesday ||
            state.course.courseDays.thursday ||
            state.course.courseDays.friday ||
            state.course.courseDays.saturday ||
            state.course.courseDays.sunday,
        },
        duration: {
          ...state.courseValidation.duration,
          isValid: actions.payload.duration > 0,
        },
        price: {
          ...state.courseValidation.price,
          isValid: actions.payload.price > 0,
        },
        startDate: {
          ...state.courseValidation.startDate,
          isValid: actions.payload.startDate !== "",
        },
        endDate: {
          ...state.courseValidation.endDate,
          isValid: actions.payload.endDate !== "",
        },
        startTime: {
          ...state.courseValidation.startTime,
          isValid: actions.payload.startTime !== "",
        },
        endTime: {
          ...state.courseValidation.endTime,
          isValid: actions.payload.endTime !== "",
        },
      };
      state.courseValidation.isValid =
        state.courseValidation.courseSpeakers.isValid &&
        state.courseValidation.type.isValid &&
        state.courseValidation.topic.isValid &&
        state.courseValidation.description.isValid &&
        state.courseValidation.format.isValid &&
        state.courseValidation.courseDays.isValid &&
        state.courseValidation.duration.isValid &&
        state.courseValidation.price.isValid &&
        state.courseValidation.startDate.isValid &&
        state.courseValidation.endDate.isValid &&
        state.courseValidation.startTime.isValid &&
        state.courseValidation.endTime.isValid;
    },
    setFieldValid(state, actions) {
      state.courseValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const courseActions = courseSlice.actions;
export default courseSlice;
