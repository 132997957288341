import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

const CourseStep1 = (props) => {
  return (
    <>
      <div
        className="mb-4 bg-blue-900 p-3 mt-4"
        style={{ marginLeft: "0px", width: "98%" }}
      >
        <h2 style={{ color: "#fff", textDecoration: "underline" }}>
          Información del curso
        </h2>
        <h2 style={{ color: "#fff" }}>
          Nombre del Curso:
          <span style={{ color: "#35c4dc", marginLeft: "1%" }}>
            {props.course &&
              props.course.topic &&
              props.course.topic.toUpperCase()}
          </span>
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-4">
            <label
              htmlFor="topic"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Tema del curso
            </label>
            <InputText
              id="topic"
              value={props.course.topic}
              onChange={(e) => props.onChangeHandler("topic", e.target.value)}
              placeholder="Tema del curso"
              style={{ width: "100%" }}
            />
            <div>{props.getFormErrorMessage("topic")}</div>
          </div>
          <div className="field col-12 md:col-4 mt-3">
            <label
              htmlFor="type"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Tipo de Capacitación:
            </label>
            &nbsp;&nbsp;
            <Dropdown
              value={{
                code: props.course.type,
                name: props.getName(props.course.type),
              }}
              onChange={(e) => props.onChangeHandler("type", e.value.code)}
              options={[
                {
                  code: "",
                  name: "Seleccionar Tipo de Curso",
                },
                {
                  code: "CA",
                  name: "Capacitación",
                },
                {
                  code: "Diplomado",
                  name: "Diplomado",
                },
              ]}
              optionLabel="name"
              placeholder="Seleccionar Tipo de Curso"
              filter
            />
            <div>{props.getFormErrorMessage("type")}</div>
          </div>
          <div className="field col-12 md:col-4">
            <label
              htmlFor="format"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Formato:
            </label>
            &nbsp;&nbsp;
            <Dropdown
              value={{
                code: props.course.format,
                name: props.getName(props.course.format),
              }}
              onChange={(e) => props.onChangeHandler("format", e.value.code)}
              options={[
                {
                  code: "",
                  name: "Seleccionar Formato",
                },
                {
                  code: "Online",
                  name: "Online",
                },
                {
                  code: "Presencial",
                  name: "Presencial",
                },
                {
                  code: "Virttual",
                  name: "Virttual",
                },
              ]}
              optionLabel="name"
              placeholder="Seleccionar Formato"
              filter
            />
            <div>{props.getFormErrorMessage("format")}</div>
          </div>

          <div className="field col-12 md:col-12">
            <label
              htmlFor="description"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Descripción del curso
            </label>
            <InputTextarea
              value={props.course.description}
              onChange={(e) =>
                props.onChangeHandler("description", e.target.value)
              }
              rows={5}
              cols={30}
              style={{ width: "100%" }}
              placeholder="Descripción del curso"
            />
            <div>{props.getFormErrorMessage("description")}</div>
          </div>
          <div className="field col-12 md:col-3">
            <label
              htmlFor="startDate"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Fecha de inicio
            </label>
            <Calendar
              id="startDate"
              value={props.startDate}
              onChange={(e) => {
                props.setStartDatetoCourse(e.value);
              }}
            />
            <div>{props.getFormErrorMessage("startDate")}</div>
          </div>
          <div className="field col-12 md:col-3">
            <label
              htmlFor="startTime"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Hora de inicio
            </label>
            <Calendar
              value={props.startTime}
              timeOnly={true}
              showTime={true}
              hourFormat="24"
              onChange={(e) => props.setStartTimetoCourse(e.value)}
            />
            <div>{props.getFormErrorMessage("startTime")}</div>
          </div>
          <div className="field col-12 md:col-3">
            <label
              htmlFor="endDate"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Fecha de fin
            </label>
            <Calendar
              id="endDate"
              value={props.endDate}
              onChange={(e) => {
                props.setEndDatetoCourse(e.value);
              }}
            />
            <div>{props.getFormErrorMessage("endDate")}</div>
          </div>
          <div className="field col-12 md:col-3">
            <label
              htmlFor="endTime"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Hora de fin
            </label>
            <Calendar
              value={props.endTime}
              timeOnly={true}
              showTime={true}
              hourFormat="24"
              onChange={(e) => props.setEndTimeCourse(e.value)}
            />
            <div>{props.getFormErrorMessage("endTime")}</div>
          </div>
         
          <div className="field col-12 md:col-6">
            <label
              htmlFor="duration"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Duración (hrs):
            </label>
            &nbsp;&nbsp;
            <InputNumber
              id={props.course.duration}
              value={props.course.duration !== undefined ? props.course.duration : ""}
              onChange={(e) => props.onChangeHandler("duration", e.value)}
              placeholder="Duración"
              style={{ width: "10%" }}
            />
            <div>{props.getFormErrorMessage("duration")}</div>
          </div>
          <div className="field col-12 md:col-6">
            <label
              htmlFor="price"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Precio (US$):
            </label>
            &nbsp;&nbsp;
            <InputNumber
              id={props.course.price}
              value={props.course.price !== undefined ? props.course.price : ""}
              onChange={(e) => props.onChangeHandler("price", e.value)}
              placeholder="Precio (US$)"
              locale="en-US"
              minFractionDigits={2}
              style={{ width: "10%" }}
            />
            <div>{props.getFormErrorMessage("price")}</div>
          </div>
          {props.buttons}
        </div>
      </div>
    </>
  );
};
export default CourseStep1;
