import { useSelector } from "react-redux";
import ABADatatable from "../ABADatatable";
import DialogModal from "../DialogDialogModal";
import { useState } from "react";
import { Button } from "primereact/button";

const CourseStep3 = (props) => {
  const auth = useSelector((state) => state.auth);
  const usuarios = useSelector((state) => state.usuario.usuarios);
  const user = useSelector((state) => state.usuario.usuario);
  const [visible, setVisible] = useState(false);
  const addUserHandler = () => {
    if (user !== undefined && user.email !== "") {
      const speaker = { user };
      props.onSpeakerSelected(speaker);
      setVisible(false);
    }
  };

  const columns = [
    {
      field: "user.nombres",
      header: "Nombres",
      filterMatchMode: "contains",
    },
    {
      field: "user.apellidos",
      header: "Apellidos",
      filterMatchMode: "contains",
    },
    {
      field: "user.email",
      header: "Email",
      filterMatchMode: "contains",
    },
    {
      field: "user.telefonoCelular",
      header: "Celular",
      filterMatchMode: "contains",
    },
    {
      field: "user.entidadLabora",
      header: "Empresa",
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <h2 style={{ color: "#fff", textDecoration: "underline" }}>
        Capacitadores
      </h2>
      <Button
        label=" Capacitador"
        icon="pi pi-user-plus"
        className="p-button-secondary"
        onClick={() => {
          setVisible(true);
        }}
      />

      <DialogModal
        email={auth.UserInfo.email}
        header="Agregar participante"
        visible={visible}
        setVisible={setVisible}
        usuario={user}
        usuarios={usuarios}
        addUserHandler={addUserHandler}
      />
      {props.course !== undefined &&
        props.course.courseSpeakers !== undefined && (
          <ABADatatable
            width="20%"
            title=""
            values={props.course.courseSpeakers}
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            Columns={columns}
            actions={[]}
            createButton={{}}
          />
        )}
      <div className="formgrid grid mt-3">{props.buttons}</div>
    </>
  );
};
export default CourseStep3;
