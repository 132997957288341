import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/index";
import './assets/Fonts/Boing-Bold_0.ttf'
import "/node_modules/primeflex/primeflex.css"


import App from "./App";
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider   store={store}>
    <App  />
  </Provider>
);
