import { useDispatch, useSelector } from "react-redux";
import { UpdateOrdenStatus } from "../../store/orden-store/orden-actions";
import YesNoModal from "../YesNoModal";
import { useEffect, useState } from "react";
import Loading from "../UI/Loading";
import { Dropdown } from "primereact/dropdown";
import { GetStatues } from "../../store/ordenstatus-store/ordenstatus-actions";

const StatusComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectedStatusMethod, setSelectedStatus] = useState({
    code: 0,
    name: "",
    color: "",
  });
  const statusList = useSelector((state) => state.ordenStatus.statues);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (props.conference.id > 0 && statusList.length === 0) {
      dispatch(GetStatues(auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, props.conference.id, dispatch, statusList]);

  let statues = [];
  if (statusList.length > 0) {
    if (props.updateOrden.status.id === 9) {
      statues = statusList.filter(
        (status) => status.status === "Activo" && status.id === 1
      );
      statues = statues.map((status) => {
        return {
          code: status.id,
          name: status.description,
          color: status.color,
        };
      });
    } else {
      statues = statusList.filter((status) => status.status === "Activo");
      statues = statues.map((status) => {
        return {
          code: status.id,
          name: status.description,
          color: status.color,
        };
      });
    }
  }
  const headerStatus = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Seleccione un estado para la orden:{" "}
        <span style={{ color: "#eec137" }}>{props.updateOrden.id}</span>
      </span>
    </>
  );
  const contentStatus = (
    <>
      {refresh === true && (
        <>
          <Loading color="#113D4D" />
        </>
      )}
      {refresh === false && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="status" style={{ fontSize: "large" }}>
              Estado actual:{" "}
              <span style={{ color: props.updateOrden.status.color }}>
                {props.updateOrden.status.description}
              </span>
              {"  "}
              Estado selecionado:{"  "}
              <span style={{ color: selectedStatusMethod.color }}>
                {selectedStatusMethod.name}
              </span>
            </label>
            <Dropdown
              id="status"
              value={selectedStatusMethod}
              onChange={(e) => {
                setSelectedStatus(e.value);
              }}
              options={statues}
              optionLabel="name"
              filter
              style={{
                width: "100%",
                borderRadius: "6px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                height: "50px",
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  const changeStatusHandler = () => {
    dispatch(
      UpdateOrdenStatus(
        props.updateOrden.id,
        selectedStatusMethod.code,
        auth.UserInfo.email
      )
    );
    setTimeout(function () {
      window.location.reload();
    }, 1500);
    setRefresh(true);
  };
  return (
    <>
      <YesNoModal
        className="p-"
        header={headerStatus}
        content={contentStatus}
        visible={props.visibleStatus}
        setVisible={props.setVisibleStatus}
        yesHandler={changeStatusHandler}
        showButton={selectedStatusMethod.code !== 0}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default StatusComponent;
