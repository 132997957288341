import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { conferenceActions } from "../../store/conferences-store/conference-slice";
import { uiActions } from "../../store/ui-slice";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { useEffect, useState, useCallback } from "react";
import { FileUpload } from "primereact/fileupload";

let restartPage = false;
let isIntervals = false;

const ConferenceForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValid = useSelector((state) => state.conference.conferenceValidation);
  const auth = useSelector((state) => state.auth);
  const conference = props.conference;
  const notification = useSelector((state) => state.ui.notification);

  const [interval, setInterveal] = useState(0);

  let startDate = new Date();
  let endDate = new Date();

  if (conference && conference.startDate) {
    startDate = new Date(conference.startDate);
  }
  if (conference && conference.endDate) {
    endDate = new Date(conference.endDate);
  }

  const changeIntervals = useCallback(() => {
    if (conference.conferencesNetworkingConfig !== undefined) {
      const configs = conference.conferencesNetworkingConfig.map((config) => {
        return { ...config, interval: interval };
      });
      dispatch(
        conferenceActions.setConference({
          conference: {
            ...conference,
            conferencesNetworkingConfig: [...configs],
          },
        })
      );
      isIntervals = false;
      setInterveal(0);
    }
  }, [conference, dispatch, interval]);

  

  useEffect(() => {
    if (isIntervals && interval > 0) {
      changeIntervals();
    }
  }, [changeIntervals, interval]);

  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      conference.id > 0 &&
      restartPage
    ) {
      window.location.href = "/conferences";
    }
  }, [conference, dispatch, navigate, notification]);

  

  const onChangeHandler = (id, value) => {
    if (id === "conferecenCodeName") {
      dispatch(
        conferenceActions.modifyPropertyValue({
          id,
          value: value.replace(" ", "").toUpperCase(),
        })
      );
    } else {
      dispatch(conferenceActions.modifyPropertyValue({ id, value }));
    }

    if (id !== "soldOut" && id !== "informativeTitle" && id !== "informativeBody") {
      if (value !== "") {
        dispatch(conferenceActions.setFieldValid({ id, value: true }));
      } else {
        dispatch(conferenceActions.setFieldValid({ id, value: false }));
      }
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (conference.name === "") {
      dispatch(conferenceActions.setFieldValid({ id: "name", value: false }));
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (conference.conferecenCodeName === "") {
      dispatch(
        conferenceActions.setFieldValid({
          id: "conferecenCodeName",
          value: false,
        })
      );
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (conference.description === "") {
      dispatch(
        conferenceActions.setFieldValid({ id: "description", value: false })
      );
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (conference.location === "") {
      dispatch(
        conferenceActions.setFieldValid({ id: "location", value: false })
      );
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (conference.price === 0) {
      dispatch(conferenceActions.setFieldValid({ id: "price", value: false }));
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (conference.startDate === "") {
      dispatch(
        conferenceActions.setFieldValid({ id: "startDate", value: false })
      );
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (conference.endDate === "") {
      dispatch(
        conferenceActions.setFieldValid({ id: "endDate", value: false })
      );
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(props.actionConference(conference, auth.UserInfo.email));
      restartPage = true;
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  const onSelectHandler = (file) => {
    dispatch(
      conferenceActions.modifyFile({
        id: "file",
        value: file.files[0],
      })
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined ",
  };

  const fileUpload = (
    <FileUpload
      style={{ width: "100%", borderRadius: "12px" }}
      name="fileUpload"
      accept="image/*"
      customUpload={true}
      onSelect={onSelectHandler}
      mode="advanced"
      chooseOptions={chooseOptions}
      uploadOptions={uploadOptions}
      cancelOptions={cancelOptions}
      emptyTemplate={<p className="m-0">Seleccione el logo del congreso.</p>}
    />
  );
  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <div className="mb-4 bg-blue-900 p-3">
          <h2 style={{ color: "#fff" }}>
            Congreso:
            <span style={{ color: "#35c4dc", marginLeft: "1%" }}>
              {conference && conference.Name && conference.Name.toUpperCase()}
            </span>
          </h2>
          <form onSubmit={onSubmitHandler}>
            <div className="formgrid grid">
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="name"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Congreso
                </label>
                <InputText
                  id="name"
                  value={conference.name}
                  onChange={(e) => onChangeHandler("name", e.target.value)}
                  placeholder="Nombre Congreso"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("name")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="conferecenCodeName"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Código del congreso
                </label>
                <InputText
                  id="conferecenCodeName"
                  value={conference.conferecenCodeName}
                  onChange={(e) =>
                    onChangeHandler("conferecenCodeName", e.target.value)
                  }
                  placeholder="Nombre Congreso"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("conferecenCodeName")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="informativeTitle"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Titulo informativo
                </label>
                <InputText
                  id="informativeTitle"
                  value={conference.informativeTitle}
                  onChange={(e) =>
                    onChangeHandler("informativeTitle", e.target.value)
                  }
                  placeholder="Titulo informativo"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="field col-12 md:col-8">
                <label
                  htmlFor="informativeBody"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Descripción Informativa
                </label>
                <InputTextarea
                  value={conference.informativeBody}
                  onChange={(e) =>
                    onChangeHandler("informativeBody", e.target.value)
                  }
                  rows={5}
                  cols={30}
                  style={{ width: "100%" }}
                  placeholder="Descripción Informativa"
                />
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="logo"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Logo:{fileUpload}
                </label>
              </div>
              <div className="field col-12 md:col-12">
                <label
                  htmlFor="description"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Descripción del congreso
                </label>
                <InputTextarea
                  value={conference.description}
                  onChange={(e) =>
                    onChangeHandler("description", e.target.value)
                  }
                  rows={5}
                  cols={30}
                  style={{ width: "100%" }}
                  placeholder="Descripción del congreso"
                />
                <div>{getFormErrorMessage("description")}</div>
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="location"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Lugar del evento
                </label>
                <InputText
                  id="location"
                  value={conference.location}
                  onChange={(e) => onChangeHandler("location", e.target.value)}
                  placeholder="Lugar del evento"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("location")}</div>
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="price"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Precio
                </label>
                <br />
                <InputNumber
                  id={conference.price}
                  value={conference.price !== undefined ? conference.price : ""}
                  onChange={(e) => onChangeHandler("price", e.value)}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  style={{ width: "10%" }}
                />
                <div>{getFormErrorMessage("price")}</div>
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="startDate"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Fecha de inicio
                </label>
                <br />
                <Calendar
                  id="startDate"
                  value={startDate}
                  onChange={(e) => {
                    onChangeHandler("startDate", e.value);
                  }}
                />
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="endDate"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Fecha de fin
                </label>
                <br />
                <Calendar
                  id="endDate"
                  value={endDate}
                  onChange={(e) => {
                    onChangeHandler("endDate", e.value);
                  }}
                />
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="soldOut"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Sold Out
                </label>
                <br />
                <InputNumber
                  id="soldOut"
                  value={
                    conference.soldOut !== undefined ? conference.soldOut : ""
                  }
                  onChange={(e) => onChangeHandler("soldOut", e.value)}
                  placeholder=""
                  style={{ width: "10%" }}
                />
              </div>
              <div className="field col-12 md:col-12">
                <Button
                  rounded
                  text
                  raised
                  style={{ backgroundColor: "#fff" }}
                  label="Regresar"
                  icon="pi pi-arrow-left"
                  className="p-button-danger"
                  onClick={() => {
                    navigate("/conferences");
                  }}
                />
                <Button
                  rounded
                  text
                  raised
                  style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                  label="Guardar"
                  className="p-button-success"
                  icon={
                    notification && notification.status === "pending"
                      ? "pi pi-spin pi-spinner"
                      : "pi pi-save"
                  }
                  disabled={notification && notification.status === "pending"}
                />
              </div>
            </div>
          </form>
        </div>
      </Card>
    </>
  );
};
export default ConferenceForms;
