import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CleanUser, GetUserByemail } from "../store/user-store/user-actions";
import { userActions } from "../store/user-store/user-slice";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import UserFormDetail from "./Forms/UserFormDetail";
import Loading from "./UI/Loading";

const DialogModal = (props) => {
  const auth = useSelector((state) => state.auth);
  const [searchEmail, setSearchEmail] = useState("");
  const dispatch = useDispatch();

  const searchUserHandler = () => {
    dispatch(GetUserByemail(searchEmail, auth.UserInfo.email, []));
  };
  const onSearchHandler = (id, value) => {
    setSearchEmail(value);
  };

  const cleanHandler = () => {
    dispatch(CleanUser());
    props.setVisible(false);
    props.setVisible(true);
    setSearchEmail("");
  };
  const cancelHanlder = () => {
    cleanHandler();
    dispatch(userActions.setFieldValid({ id: "name", value: true }));
    dispatch(userActions.setFieldValid({ id: "lastName", value: true }));
    dispatch(userActions.setFieldValid({ id: "email", value: true }));
    dispatch(userActions.setFieldValid({ id: "cellPhone", value: true }));
    dispatch(userActions.setFieldValid({ id: "country", value: true }));
    props.setVisible(false);
  };

  const footerContent = (
    <div style={{marginTop:"5px"}}>
      <Button
        rounded
        text
        raised
        type="button"
        label="Agregar"
        icon="pi pi-check"
        onClick={() => {
          props.addUserHandler();
        }}
        className="p-button-success"
      />
      <Button
        rounded
        text
        raised
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => cancelHanlder()}
        className="p-button-danger"
      />
    </div>
  );

  const content = (
    <>
      <div
        className="p-inputgroup"
        style={{ marginBottom: "2px", width: "100%" }}
      >
        <InputText
          placeholder="example@email.com"
          value={searchEmail}
          st
          onChange={(e) => onSearchHandler("searchEmail", e.target.value)}
        />
        <Button
          rounded
          icon="pi pi-search"
          className="p-button-warning"
          style={{ marginLeft: "2px", marginRight: "2px" }}
          tooltip="Buscar"
          onClick={() => searchUserHandler()}
        />
        <Button
          rounded
          icon="pi pi-filter-slash"
          aria-label="Filter"
          tooltip="Limpiar"
          onClick={() => cleanHandler()}
        />
      </div>
      {props.refresh === true && (
        <>
          <Loading color="#113D4D" />
        </>
      )}
      {props.refresh === false && (
        <div style={{ marginTop: "18px" }}>
          <UserFormDetail isCreation={true} />
        </div>
      )}
    </>
  );

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={props.header}
        visible={props.visible}
        style={{ width: "100%", height: "100%" }}
        onHide={() => props.setVisible(false)}
        footer={footerContent}
        position="center"
      >
        {content}
      </Dialog>
    </div>
  );
};
export default DialogModal;
