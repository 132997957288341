import { Checkbox } from "primereact/checkbox";

const CourseStep2 = (props) => {
  return (
    <>
      <div
        className="mb-4 bg-blue-900 p-3 mt-4"
        style={{ marginLeft: "0px", width: "98%" }}
      >
        <h2 style={{ color: "#fff", textDecoration: "underline" }}>
          Días del curso
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="monday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Lunes
            </label>
            <Checkbox
              inputId="monday"
              name="monday"
              value={props.course.courseDays.monday}
              checked={props.course.courseDays.monday}
              onChange={(e) => {
                props.onDaySelected("monday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="tuesday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Martes
            </label>
            <Checkbox
              inputId="tuesday"
              name="tuesday"
              value={props.course.courseDays.tuesday}
              checked={props.course.courseDays.tuesday}
              onChange={(e) => {
                props.onDaySelected("tuesday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="wednesday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Miércoles
            </label>
            <Checkbox
              inputId="wednesday"
              name="wednesday"
              value={props.course.courseDays.wednesday}
              checked={props.course.courseDays.wednesday}
              onChange={(e) => {
                props.onDaySelected("wednesday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="thursday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Jueves
            </label>
            <Checkbox
              inputId="thursday"
              name="thursday"
              value={props.course.courseDays.thursday}
              checked={props.course.courseDays.thursday}
              onChange={(e) => {
                props.onDaySelected("thursday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="friday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Viernes
            </label>
            <Checkbox
              inputId="friday"
              name="friday"
              value={props.course.courseDays.friday}
              checked={props.course.courseDays.friday}
              onChange={(e) => {
                props.onDaySelected("friday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="saturday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Sábado
            </label>
            <Checkbox
              inputId="saturday"
              name="saturday"
              value={props.course.courseDays.saturday}
              checked={props.course.courseDays.saturday}
              onChange={(e) => {
                props.onDaySelected("saturday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-1" style={{ marginLeft: "4%" }}>
            <label
              htmlFor="sunday"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginRight: "10px",
              }}
            >
              Domingo
            </label>
            <Checkbox
              inputId="sunday"
              name="sunday"
              value={props.course.courseDays.sunday}
              checked={props.course.courseDays.sunday}
              onChange={(e) => {
                props.onDaySelected("sunday", !e.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-12"></div>
          {props.buttons}
        </div>
      </div>
    </>
  );
};
export default CourseStep2;
