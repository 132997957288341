import "./App.css";
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { authProvider } from "./store/AuthProvider";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "./store/auth-slice";

import AdminLayout from "./layouts/AdminLayout";
import ErrorPage from "./pages/ErrorPage";
import AdminHomePage from "./pages/Admin/AdminHomePage";

//cursos
import CourseHomePAge from "./pages/Admin/Courses/CourseHomePage";
import CourseDetail from "./pages/Admin/Courses/CourseDetail";
import CourseEdit from "./pages/Admin/Courses/CourseEdit";
import CourseCreate from "./pages/Admin/Courses/CourseCreate";

//dolar diario
import DolarDiarioEdit from "./pages/Admin/DolarDiario/DolarDiarioEdit";

//Descuentos
import DiscountHomePage from "./pages/Admin/Discounts/DiscountHomePage";
import DiscountCreate from "./pages/Admin/Discounts/DiscountCreate";
import DiscountEdit from "./pages/Admin/Discounts/DiscountEdit";
import DiscountDetail from "./pages/Admin/Discounts/DiscountDetail";

//Orden Status
import OrdenStatusHomePage from "./pages/Admin/OrdenStatus/OrdenStatusHomePage.js";
import OrdenStatusCreate from "./pages/Admin/OrdenStatus/OrdenStatusCreate";
import OrdenStatusEdit from "./pages/Admin/OrdenStatus/OrdenStatusEdit";
import OrdenStatusDetail from "./pages/Admin/OrdenStatus/OrdenStatusDetail.js";

//tipos de ordenes
import OrdenTypeHomePage from "./pages/Admin/OrdenType/OrdenTypeHomePage";
import OrdenTypeCreate from "./pages/Admin/OrdenType/OrdenTypeCreate";
import OrdenTypeEdit from "./pages/Admin/OrdenType/OrdenTypeEdit";
import OrdenTypeDetail from "./pages/Admin/OrdenType/OrdenTypeDetail";

//Metodos de pago
import PaymentMethodHomePage from "./pages/Admin/PaymentMethod/PaymentMethodHomePage.js";
import PaymentMethodCreate from "./pages/Admin/PaymentMethod/PaymentMethodCreate.js";
import PaymentMethodEdit from "./pages/Admin/PaymentMethod/PaymentMethodEdit";
import PaymentMethodDetail from "./pages/Admin/PaymentMethod/PaymentMethodDetail";

//Users
import UserHomePage from "./pages/Admin/Users/UserHomePage";
import UserDetail from "./pages/Admin/Users/UserDetail";
import UserEdit from "./pages/Admin/Users/UserEdit";
import UserCreate from "./pages/Admin/Users/UserCreate";
import UserBadges from "./pages/Admin/Users/UserBadges";

//Charges
import ChargeHomePage from "./pages/Admin/Charges/ChargeHomePage";
import ChargeCreate from "./pages/Admin/Charges/ChargeCreate.js";
import ChargeEdit from "./pages/Admin/Charges/ChargeEdit.js";
import ChargeDetail from "./pages/Admin/Charges/ChargeDetail.js";

//Departments
import DepartmentHomePage from "./pages/Admin/Department/DepartmentHomePage.js";
import DepartmentCreate from "./pages/Admin/Department/DepartmentCreate.js";
import DepartmentEdit from "./pages/Admin/Department/DepartmentEdit.js";
import DepartmentDetail from "./pages/Admin/Department/DepartmentDetail.js";


//congresos
import ConferencesHomePage from "./pages/Admin/Conferences/ConferencesHomePage";
import ConferencesCreate from "./pages/Admin/Conferences/ConferencesCreate";
import ConferencesEdit from "./pages/Admin/Conferences/ConferencesEdit";
import ConferenceDetail from "./pages/Admin/Conferences/ConferencesDetail.js";

//Conferencias
import ConferenceEventsHomePage from "./pages/Admin/ConferenceEvents/ConferenceEventsHomePage";
import ConferenceEventDetail from "./pages/Admin/ConferenceEvents/ConferenceEventDetail";
import ConferenceEventEdit from "./pages/Admin/ConferenceEvents/ConferenceEventEdit";
import ConferenceEventCreate from "./pages/Admin/ConferenceEvents/ConferenceEventCreate";

//patrocinios
import SponsorsHomePage from "./pages/Admin/Sponsors/SponsorsHomePage.js";
import SponsorCreate from "./pages/Admin/Sponsors/SponsorCreate.js";
import SponsorPaymentLink from "./pages/Admin/Sponsors/SponsorPaymentLink.js";
import SponsorDetail from "./pages/Admin/Sponsors/SponsorDetail";

//Orden
import OrdenDetail from "./pages/Admin/Orden/OrdenDetail";
import DataLabUpladFileForms from "./components/Forms/DataLabUpladFileForms.js";
// import Certificate from "./Templates/Certicate.js";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const account = authProvider.getAccount();
    if (authProvider.authenticationState) {
      dispatch(
        authActions.login({
          UserInfo: {
            id: account != null ? account.accountIdentifier : "",
            name: account != null ? account.name : "",
            email: account != null ? account.userName : "",
          },
          isAuthenticate: account != null ? true : false,
          roles: account != null ? account.idToken.roles : [],
        })
      );
    }
  }, [dispatch]);

  const routeDefinitions = createRoutesFromElements(
    <Route>
      <Route path="*" element={<ErrorPage />} />
      <Route
        path="/"
        element={<AdminLayout />}
        children={[
          <Route key="DataLabUpladFileForms" path="/datalab/upload" element={<DataLabUpladFileForms />} />,
          <Route key="AdminHomePage" path="/" element={<AdminHomePage />} />,
          //dolar diario
          <Route
            key="DolarDiarioEdit"
            path="/dolardiario/edit/:dolarDiarioId"
            element={<DolarDiarioEdit />}
          />,
          //Discounts
          <Route
            key="DiscountHomePage"
            path="/discounts"
            element={<DiscountHomePage />}
          />,
          <Route
            key="DiscountCreate"
            path="/discount/create"
            element={<DiscountCreate />}
          />,
          <Route
            key="DiscountEdit"
            path="/discount/edit/:discountId"
            element={<DiscountEdit />}
          />,
          <Route
            key="DiscountDetail"
            path="/discount/view/:discountId"
            element={<DiscountDetail />}
          />,
          //Cursos
          <Route
            key="CourseHomePage"
            path="/courses"
            element={<CourseHomePAge />}
          />,
          <Route
            key="CourseDetail"
            path="/course/view/:courseId"
            element={<CourseDetail />}
          />,
          <Route
            key="CourseEdit"
            path="/course/edit/:courseId"
            element={<CourseEdit />}
          />,
          <Route
            key="CourseCreate"
            path="/course/create"
            element={<CourseCreate />}
          />,
          //users
          <Route key="UserHomePage" path="/users" element={<UserHomePage />} />,
          <Route
            key="UserCreate"
            path="/user/create"
            element={<UserCreate />}
          />,
          <Route
            key="UserDetail"
            path="/user/detail/:userId"
            element={<UserDetail />}
          />,
          <Route
            key="UserEdit"
            path="/user/edit/:userId"
            element={<UserEdit />}
          />,

          //charge
          <Route
            key="ChargeHomePage"
            path="/charges"
            element={<ChargeHomePage />}
          />,
          <Route
            key="ChargeCreate"
            path="/charge/create"
            element={<ChargeCreate />}
          />,
          <Route
            key="ChargeEdit"
            path="/charge/edit/:chargeId"
            element={<ChargeEdit />}
          />,
          <Route
            key="ChargeEdit"
            path="/charge/detail/:chargeId"
            element={<ChargeDetail />}
          />,
          //Department
          <Route
            key="DepartmentHomePage"
            path="/departments"
            element={<DepartmentHomePage />}
          />,
          <Route
            key="DepartmentCreate"
            path="/department/create"
            element={<DepartmentCreate />}
          />,
          <Route
            key="DepartmentEdit"
            path="/department/edit/:departmentId"
            element={<DepartmentEdit />}
          />,
          <Route
            key="DepartmentDetail"
            path="/department/detail/:departmentId"
            element={<DepartmentDetail />}
          />,
          //Estados de las ordenes
          <Route
            key="StatusHomePage"
            path="/orden/statues"
            element={<OrdenStatusHomePage />}
          />,
          <Route
            key="StatusCreate"
            path="/orden/status/create"
            element={<OrdenStatusCreate />}
          />,
          <Route
            key="StatusEdit"
            path="/orden/status/edit/:statusId"
            element={<OrdenStatusEdit />}
          />,
          <Route
            key="StatusDetail"
            path="/orden/status/detail/:statusId"
            element={<OrdenStatusDetail />}
          />,

          //Tipo de ordenes
          <Route
            key="OrdenTypeHomePage"
            path="/orden/types"
            element={<OrdenTypeHomePage />}
          />,
          <Route
            key="OrdenTypeCreate"
            path="/orden/type/create"
            element={<OrdenTypeCreate />}
          />,
          <Route
            key="OrdenTypeEdit"
            path="/orden/type/edit/:ordenTypeId"
            element={<OrdenTypeEdit />}
          />,
          <Route
            key="OrdenTypeDetail"
            path="/orden/type/detail/:ordenTypeId"
            element={<OrdenTypeDetail />}
          />,
          //Metodos de pago
          <Route
            key="PaymentMethodHomePage"
            path="/orden/paymentmethods"
            element={<PaymentMethodHomePage />}
          />,
          <Route
            key="PaymentMethodCreate"
            path="/orden/paymentmethod/create"
            element={<PaymentMethodCreate />}
          />,
          <Route
            key="PaymentMethodEdit"
            path="/orden/paymentmethod/edit/:paymentMethodId"
            element={<PaymentMethodEdit />}
          />,
          <Route
            key="PaymentMethodDetail"
            path="/orden/paymentmethod/detail/:paymentMethodId"
            element={<PaymentMethodDetail />}
          />,


          //Conference
          <Route
            key="ConferencesHomePage"
            path="/conferences"
            element={<ConferencesHomePage />}
          />,
          <Route
            key="ConferencesCreate"
            path="/conference/create"
            element={<ConferencesCreate />}
          />,
          <Route
            key="ConferencesEdit"
            path="/conference/edit/:conferenceId"
            element={<ConferencesEdit />}
          />,
          <Route
            key="ConferenceDetail"
            path="/conference/detail/:conferenceId"
            element={<ConferenceDetail />}
          />,
          /*******ConferenceEvent */
          <Route
            key="ConferenceEventsHomePage"
            path="/conference/events/:conferenceId"
            element={<ConferenceEventsHomePage />}
          />,
          <Route
            key="ConferenceEventDetail"
            path="/conference/event/detail/:conferenceEventId"
            element={<ConferenceEventDetail />}
          />,
          <Route
            key="ConferencesEdit"
            path="/conference/event/edit/:conferenceId/:conferenceEventId"
            element={<ConferenceEventEdit />}
          />,
          <Route
            key="ConferenceEventCreate"
            path="/conference/event/create/:conferenceId"
            element={<ConferenceEventCreate />}
          />,

          /******* END ConferenceEvent */
          //Sponosorship
          <Route
            key="SponsorshipsHomePage"
            path="/admin/sponsorships"
            element={<SponsorsHomePage />}
          />,
          <Route
            key="SponsorshipCreate"
            path="/admin/createsponsorship"
            element={<SponsorCreate />}
          />,
          <Route
            key="SponsorShipPaymentLink"
            path="/admin/sponsorship/linkPayment/:sponsorshipId"
            element={<SponsorPaymentLink />}
          />,
          <Route
            key="SponsorDetail"
            path="/admin/sponsorship/detail/:sponsorshipId"
            element={<SponsorDetail />}
          />,
          /******* END Sponosorship */
          <Route
            key="OrdenDetail"
            path="/admin/orden/:ordenId"
            element={<OrdenDetail />}
          />,
          <Route
            key="UserBadges"
            path="/admin/userBadges/:ordenId"
            element={<UserBadges />}
          />,
          // <Route
          //   key="certificado"
          //   path="/admin/certificado/"
          //   element={<Certificate />}
          // />,
          
        ]}
      />
    </Route>
  );
  const router = createBrowserRouter(routeDefinitions);

  return <RouterProvider router={router} />;
}
export default App;

// set HTTPS=true&&
