const ConferenceDetailComponent = (props) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const startDateConference = new Date(
    props.conference.startDate
  ).toLocaleDateString("es-ES", options);

  return (
    <>
      <div className="formgrid grid" style={{ marginBottom: "-50px" }}>
        <div className="field col-12 md:col-12 mt-3">
          <label
            style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
          >
            Nombre del evento:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.conference.name.toUpperCase()}
          </label>
        </div>
        <div className="field col-12 md:col-12 mt-3">
          <label
            style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
          >
            Descripción:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.conference.description}
          </label>
        </div>
        {props.showInfo === undefined && (
          <div className="field col-12 md:col-3 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Precio:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {USDollar.format(props.conference.price)}
            </label>
          </div>
        )}
        {props.showInfo === undefined && (
          <div className="field col-12 md:col-3 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Lugar:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.conference.location}
            </label>
          </div>
        )}
        {props.showInfo === undefined && (
          <div className="field col-12 md:col-3 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Fecha de inicio:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {startDateConference}
            </label>
          </div>
        )}

        <div className="field col-12 md:col-4 mt-3">
          {props.statusConference}
        </div>
        {props.isDetail && (
          <div className="field col-12 md:col-4 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Url Login:
            </label>{" "}
            {props.conference.loginUrl}
          </div>
        )}
        <div className="field col-12 md:col-12 mt-3">{props.buttons}</div>
      </div>
    </>
  );
};
export default ConferenceDetailComponent;
