import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

import { courseActions } from "../../store/courses-store/course-slice";
import { uiActions } from "../../store/ui-slice";
import { useEffect, useState } from "react";
import { Steps } from "primereact/steps";
import "./StepsDemo.css";
import CourseStep1 from "../CourseSteps/CourseStep1";
import CourseStep2 from "../CourseSteps/CourseStep2";
import CourseStep3 from "../CourseSteps/CourseStep3";

const CourseForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const isValid = useSelector((state) => state.course.courseValidation);
  const notification = useSelector((state) => state.ui.notification);
  const [startTime, setStartTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);

  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  const course = props.course;

  useEffect(() => {
    if (
      course &&
      course.id !== undefined &&
      course.id !== "" &&
      course.endDate !== undefined &&
      endDate === undefined
    ) {
      setEndDate(new Date(course.endDate + "T01:00:00"));
    }
  }, [course, endDate]);

  useEffect(() => {
    if (
      course &&
      course.id !== undefined &&
      course.id !== "" &&
      course.startDate !== undefined &&
      startDate === undefined
    ) {
      setStartDate(new Date(course.startDate + "T01:00:00"));
    }
  }, [course, startDate]);

  useEffect(() => {
    if (
      course &&
      course.id !== undefined &&
      course.id !== "" &&
      course.startTime !== undefined &&
      startTime === undefined
    ) {
      setStartTime(new Date("01 Jan 1970 " + course.startTime));
    }
  }, [course, startTime]);

  useEffect(() => {
    if (
      course &&
      course.id !== undefined &&
      course.id !== "" &&
      course.endTime !== undefined &&
      endTime === undefined
    ) {
      setEndTime(new Date("01 Jan 1970 " + course.endTime));
    }
  }, [course, endTime]);

  const onChangeHandler = (id, value) => {
    dispatch(courseActions.modifyPropertyValue({ id, value }));
    if (value !== "") {
      dispatch(courseActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(courseActions.setFieldValid({ id, value: false }));
    }
  };
  const onDaySelected = (day, value) => {
    dispatch(courseActions.setDays({ day }));
  };
  const onSpeakerSelected = (speaker) => {
    if (!course.courseSpeakers.find((x) => x.user.id === speaker.user.id)) {
      dispatch(courseActions.setSpeaker({ speaker }));
    }
  };
  const setStartDatetoCourse = (value) => {
    setStartDate(value);
    dispatch(
      courseActions.modifyPropertyValue({
        id: "startDate",
        value: value.toDateString(),
      })
    );
  };
  const setEndDatetoCourse = (value) => {
    setEndDate(value);
    dispatch(
      courseActions.modifyPropertyValue({
        id: "endDate",
        value: value.toDateString(),
      })
    );
  };
  const setStartTimetoCourse = (value) => {
    const time = value.toTimeString().split(" ")[0];
    setStartTime(value);
    dispatch(
      courseActions.modifyPropertyValue({ id: "startTime", value: time })
    );
  };
  const setEndTimeCourse = (value) => {
    const time = value.toTimeString().split(" ")[0];
    setEndTime(value);
    dispatch(
      courseActions.modifyPropertyValue({
        id: "endTime",
        value: time,
      })
    );
  };

  const onSelectImageHandler = (file) => {
    dispatch(
      courseActions.modifyPropertyValue({ id: "image", value: file.files[0] })
    );
  };
  const onSelectBrochureHandler = (file) => {
    dispatch(
      courseActions.modifyPropertyValue({
        id: "brochure",
        value: file.files[0],
      })
    );
  };

  const onSubmitHandler = () => {
    dispatch(courseActions.setIsValid({ ...course }));

    if (!isValid.isValid) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      const newCorse = {
        ...course,
      };

      dispatch(props.actionCourse(newCorse, auth.UserInfo.email));
      setTimeout(() => {
        window.location.href = "/courses";
      }, 1000);
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  const getName = (type) => {
    if (type === "CA") {
      return "Capacitación";
    } else if (type === "Diplomado") {
      return "Diplomado";
    } else if (type === "Online") {
      return "Online";
    } else if (type === "Presencial") {
      return "Presencial";
    } else if (type === "Virttual") {
      return "Virttual";
    }
  };

  const steps = [
    {
      template: () => {
        return (
          <>
            <Button
              rounded
              className={
                activeIndex === 0 ? "p-button-primary" : "p-button-secondary"
              }
              aria-label="Filter"
              style={{
                height: "50px",
                width: "50px",
                marginTop: "5px",
                marginBottom: "5px",
                marginLeft: "0px",
                padding: "0px",
                textAlign: "center",
                cursor: "default",
              }}
            >
              <span
                style={{
                  marginLeft: "40%",
                  fontSize: "x-large",
                  fontWeight: "bold",
                }}
              >
                1
              </span>
            </Button>
          </>
        );
      },
    },
    {
      template: () => {
        return (
          <>
            <Button
              icon="pi"
              label=""
              rounded
              className={
                activeIndex === 1 ? "p-button-primary" : "p-button-secondary"
              }
              aria-label="Filter"
              style={{
                height: "50px",
                width: "50px",
                marginTop: "5px",
                marginBottom: "5px",
                marginLeft: "-10px",
                padding: "0px",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  marginLeft: "35%",
                  fontSize: "x-large",
                  fontWeight: "bold",
                }}
              >
                2
              </span>
            </Button>
          </>
        );
      },
    },
    {
      template: () => {
        return (
          <>
            <Button
              icon="pi"
              label=""
              rounded
              className={
                activeIndex === 2 ? "p-button-primary" : "p-button-secondary"
              }
              aria-label="Filter"
              style={{
                height: "50px",
                width: "50px",
                marginTop: "5px",
                marginBottom: "5px",
                marginLeft: "-10px",
                padding: "0px",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  marginLeft: "35%",
                  fontSize: "x-large",
                  fontWeight: "bold",
                }}
              >
                3
              </span>
            </Button>
          </>
        );
      },
    },
  ];
  const buttons = (
    <>
      <div className="formgrid grid pr-3 pl-3">
        {activeIndex === 0 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              style={{ marginLeft: "1%", borderRadius: "6px", width: "100%" }}
              onClick={() => {
                navigate("/courses");
              }}
            />
          </div>
        )}
        {activeIndex > 0 && activeIndex < 3 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                const back = activeIndex - 1;
                setActiveIndex(back);
              }}
              style={{
                width: "100%",
                border: "solid 0.5px #2196f3",
                borderRadius: "6px",
              }}
            />
          </div>
        )}
        {activeIndex < 2 && activeIndex < 3 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Siguiente"
              type="button"
              icon="pi pi-arrow-right"
              className="p-button-primary"
              onClick={() => {
                const next = activeIndex + 1;
                setActiveIndex(next);
              }}
              style={{ width: "100%", borderRadius: "6px" }}
            />
          </div>
        )}
        {activeIndex === 2 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Guardar"
              className="p-button-success"
              onClick={() => {
                onSubmitHandler();
              }}
              icon={
                notification && notification.status === "pending"
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-save"
              }
              style={{ width: "100%", borderRadius: "6px" }}
            />
          </div>
        )}
      </div>
    </>
  );
  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          boxShadow: "none",
          border: "solid #fff 0.5px",
        }}
      >
        <div className="card">
          <Steps
            model={steps}
            activeIndex={activeIndex}
            style={{
              backgroundColor: "#142f47",
            }}
          />
        </div>
        {activeIndex === 0 && (
          <CourseStep1
            course={course}
            onChangeHandler={onChangeHandler}
            startDate={startDate}
            setStartDatetoCourse={setStartDatetoCourse}
            endDate={endDate}
            setEndDatetoCourse={setEndDatetoCourse}
            startTime={startTime}
            setStartTimetoCourse={setStartTimetoCourse}
            endTime={endTime}
            setEndTimeCourse={setEndTimeCourse}
            getName={getName}
            getFormErrorMessage={getFormErrorMessage}
            buttons={buttons}
          />
        )}
        {activeIndex === 1 && (
          <CourseStep2
            course={course}
            onChangeHandler={onChangeHandler}
            onDaySelected={onDaySelected}
            getName={getName}
            getFormErrorMessage={getFormErrorMessage}
            buttons={buttons}
          />
        )}
        {activeIndex === 2 && (
          <CourseStep3
            course={course}
            onSpeakerSelected={onSpeakerSelected}
            buttons={buttons}
          />
        )}
      </Card>
    </>
  );
};
export default CourseForms;
