import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Rating } from "primereact/rating";
import { conferenceEventActions } from "../../store/conferencesEvent-store/conferenceEvent-slice";
import { GetConferenceById } from "../../store/conferences-store/conference-actions";

import Loading from "../UI/Loading";

let restartPage = false;

const ConferenceEventForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const conferenceEvent = props.conferenceEvent;
  const isValid = useSelector(
    (state) => state.conferenceEvent.conferenceEventValidation
  );
  const notification = useSelector((state) => state.ui.notification);
  const auth = useSelector((state) => state.auth);
  const conference = useSelector((state) => state.conference.conference);
  const [startingHour, setStartingHour] = useState(new Date());
  const [endHour, setEndHour] = useState(new Date());
  const [eventDate, setEventDate] = useState(new Date());
  const [askQuestions, setAskQuestions] = useState(false);
  const [ratingEvent, setRatingEvent] = useState(false);

  useEffect(() => {
    if (conferenceEvent && conferenceEvent.eventDate) {
      setEventDate(new Date(conferenceEvent.eventDate));
    } else {
      setEventDate(new Date());
    }
  }, [conferenceEvent]);

  useEffect(() => {
    if (conferenceEvent && conferenceEvent.StartTime) {
      setStartingHour(new Date("01 Jan 1970 " + conferenceEvent.startTime));
    } else {
      setStartingHour(new Date("01 Jan 1970 00:00"));
    }
  }, [conferenceEvent]);

  useEffect(() => {
    if (conferenceEvent && conferenceEvent.endTime) {
      setEndHour(new Date("01 Jan 1970 " + conferenceEvent.endTime));
    } else {
      setEndHour(new Date("01 Jan 1970 00:00"));
    }
  }, [conferenceEvent]);

  useEffect(() => {
    if (conferenceEvent && conferenceEvent.startQuestions) {
      setAskQuestions(conferenceEvent.startQuestions);
    }
  }, [conferenceEvent]);

  useEffect(() => {
    if (conferenceEvent && conferenceEvent.startRating) {
      setRatingEvent(conferenceEvent.startRating);
    }
  }, [conferenceEvent]);

  useEffect(() => {
    if (conference && (conference.id === undefined || conference.id === 0)) {
      dispatch(GetConferenceById(params.conferenceId, auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, conference, dispatch, params.conferenceId]);

  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      conference.id > 0 &&
      restartPage
    ) {
      window.location.href = `/conference/events/${conference.id}`;
    }
  }, [conference, dispatch, navigate, notification]);

  const onChangeHandler = (id, value) => {
    dispatch(conferenceEventActions.modifyPropertyValue({ id, value }));
    if (value === "name") {
      if (value) {
        dispatch(conferenceEventActions.setFieldValid({ id, value: true }));
      } else {
        dispatch(conferenceEventActions.setFieldValid({ id, value: false }));
      }
    }
  };
  const onClickHandler = () => {
    navigate(`/conference/events/${params.conferenceId}`);
  };
  const getFormErrorMessage = (id) => {
    if (id !== undefined && isValid[id] !== undefined) {
      return !isValid[id].isValid ? (
        <Message
          severity="warn"
          style={{ width: "100%" }}
          text={isValid[id].errorMessage}
        />
      ) : (
        <small className="p-warn">&nbsp;</small>
      );
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (conferenceEvent.name === "" || conferenceEvent.name === undefined) {
      dispatch(
        conferenceEventActions.setFieldValid({ id: "name", value: false })
      );
    } else {
      const updatedConferenceEvent = {
        ...conferenceEvent,
        conferenceId: conference.id,
        eventDate: eventDate.toDateString(),
        startTime: startingHour.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        endTime: endHour.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        startQuestions: askQuestions,
        startRating: ratingEvent,
      };
      dispatch(
        props.actionConference(updatedConferenceEvent, auth.UserInfo.email)
      );
      restartPage = true;
    }
  };
  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginLeft: "0%",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <div className="mb-4 bg-blue-900 p-3">
          <div className="formgrid grid">
            <div className="field col-12">
              <label
                htmlFor="IdCongreso"
                style={{ fontSize: "xx-large", color: "#35c4dc" }}
              >
                Congreso:
              </label>{" "}
              <label
                htmlFor="IdCongreso"
                style={{ fontSize: "xx-large", color: "#fff" }}
              >
                {conference.name}
              </label>
            </div>
          </div>
          {conference &&
            (conference.id === 0 || conference.id === undefined) && (
              <>
                <Loading />
              </>
            )}
          {conference && conference.id > 0 && conference.id !== undefined && (
            <>
              <h2 style={{ color: "#fff" }}>
                Conferencia:
                <span style={{ color: "#35c4dc", marginLeft: "1%" }}>
                  {conferenceEvent && conferenceEvent.name && (
                    <>
                      {conferenceEvent.name.toUpperCase()}
                      <Rating
                        value={conferenceEvent.rating}
                        onIcon={
                          <i
                            className="pi pi-star-fill"
                            style={{ color: "#F9C02D" }}
                          ></i>
                        }
                        readOnly
                        cancel={false}
                      />
                    </>
                  )}
                </span>
              </h2>
              <form onSubmit={onSubmitHandler}>
                <div className="formgrid grid">
                  <div className="field col-12 md:col-6">
                    <label
                      htmlFor="Name"
                      style={{ fontSize: "large", color: "#35c4dc" }}
                    >
                      Nombre de la conferencia
                    </label>
                    <InputText
                      id="Name"
                      value={
                        conferenceEvent && conferenceEvent.name
                          ? conferenceEvent.name
                          : ""
                      }
                      onChange={(e) => onChangeHandler("name", e.target.value)}
                      placeholder="Nombre de la conferencia"
                      style={{ width: "100%" }}
                    />
                    <div>{getFormErrorMessage("name")}</div>
                  </div>
                  <div className="field col-12 md:col-6">
                    <label
                      htmlFor="Location"
                      style={{ fontSize: "large", color: "#35c4dc" }}
                    >
                      Lugar de la conferencia
                    </label>
                    <InputText
                      id="Location"
                      value={
                        conferenceEvent && conferenceEvent.location
                          ? conferenceEvent.location
                          : ""
                      }
                      onChange={(e) =>
                        onChangeHandler("location", e.target.value)
                      }
                      placeholder="Lugar de la conferencia"
                      style={{ width: "100%" }}
                    />
                  </div>
                  {props.editMode && (
                    <>
                      <div className="field col-12 md:col-2">
                        <label
                          htmlFor="EndHour"
                          style={{
                            fontSize: "large",
                            color: "#35c4dc",
                            marginRight: "10px",
                          }}
                        >
                          Hacer Preguntas
                        </label>
                        <br />

                        <InputSwitch
                          checked={askQuestions}
                          onChange={(e) => setAskQuestions(e.value)}
                        />
                      </div>
                      <div className="field col-12 md:col-2">
                        <label
                          htmlFor="EndHour"
                          style={{
                            fontSize: "large",
                            color: "#35c4dc",
                            marginRight: "10px",
                          }}
                        >
                          Valor la conferencia
                        </label>
                        <br />

                        <InputSwitch
                          checked={ratingEvent}
                          onChange={(e) => setRatingEvent(e.value)}
                        />
                      </div>
                    </>
                  )}
                  <div className="field col-12 md:col-2">
                    <label
                      htmlFor="EventDate"
                      style={{
                        fontSize: "large",
                        color: "#35c4dc",
                        marginRight: "10px",
                      }}
                    >
                      Fecha de inicio
                    </label>
                    <br />
                    <Calendar
                      id="EventDate"
                      value={eventDate}
                      onChange={(e) => {
                        setEventDate(e.value);
                      }}
                    />
                  </div>
                  <div className="field col-12 md:col-2">
                    <label
                      htmlFor="StartingHour"
                      style={{
                        fontSize: "large",
                        color: "#35c4dc",
                        marginRight: "10px",
                      }}
                    >
                      Hora de inicio
                    </label>
                    <br />

                    <Calendar
                      value={startingHour}
                      timeOnly={true}
                      showTime={true}
                      hourFormat="12"
                      onChange={(e) => setStartingHour(e.value)}
                    />
                  </div>
                  <div className="field col-12 md:col-2">
                    <label
                      htmlFor="EndHour"
                      style={{
                        fontSize: "large",
                        color: "#35c4dc",
                        marginRight: "10px",
                      }}
                    >
                      Hora de inicio
                    </label>
                    <br />

                    <Calendar
                      value={endHour}
                      timeOnly={true}
                      showTime={true}
                      hourFormat="12"
                      onChange={(e) => setEndHour(e.value)}
                    />
                  </div>

                  <div className="field col-12 md:col-6">
                    <Button
                      rounded
                      text
                      raised
                      style={{ backgroundColor: "#fff" }}
                      label="Regresar"
                      icon="pi pi-arrow-left"
                      className="p-button-danger"
                      onClick={onClickHandler}
                    />
                    <Button
                      rounded
                      text
                      raised
                      style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                      label="Guardar"
                      className="p-button-success"
                      icon={
                        notification && notification.status === "pending"
                          ? "pi pi-spin pi-spinner"
                          : "pi pi-save"
                      }
                    />
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </Card>
    </>
  );
};
export default ConferenceEventForm;
