import { useDispatch, useSelector } from "react-redux";
import YesNoModal from "../YesNoModal";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import Loading from "../UI/Loading";
import { UpdateDiscountCodeMethod } from "../../store/orden-store/orden-actions";
import { ValidateDiscountByCodeAndOrdenId } from "../../store/discount-store/discount-actions";

const DiscountComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const discountValidation = useSelector(
    (state) => state.discount.discountValidation.discountCode.isValid
  );
  const dispatch = useDispatch();

  const [selectDiscountCode, setSelectDiscountCode] = useState("");

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (
      selectDiscountCode !== undefined &&
      selectDiscountCode !== "" &&
      props.visibleDiscountCode === true
    ) {
      dispatch(
        ValidateDiscountByCodeAndOrdenId(
          props.updateOrden.id,
          selectDiscountCode,
          auth.UserInfo.email
        )
      );
    }
  }, [
    auth.UserInfo.email,
    dispatch,
    props.orden.discountCode,
    selectDiscountCode,
    props.updateOrden.id,
    props.visibleDiscountCode,
  ]);

  const headerDiscountCode = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Código de Descuento de la orden:{" "}
        <span style={{ color: "#eec137" }}>{props.updateOrden.id}</span>
      </span>
    </>
  );
  const contentDiscountCode = (
    <>
      {refresh === true && (
        <>
          <Loading color="#113D4D" />
        </>
      )}
      {refresh === false && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <label
              htmlFor="paymentMethod"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Código de descuento:
            </label>
            <InputText
              id="discountCode"
              value={selectDiscountCode}
              onChange={(e) => setSelectDiscountCode(e.target.value)}
              style={{
                width: "100%",
                borderRadius: "6px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                height: "50px",
                borderColor:
                  props.orden.discountCode !== "" &&
                  discountValidation !== undefined &&
                  discountValidation === true
                    ? "green"
                    : props.orden.discountCode !== "" &&
                      discountValidation !== undefined &&
                      discountValidation === false
                    ? "red"
                    : "",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
  const changeDiscountCodeHandler = () => {
    dispatch(
      UpdateDiscountCodeMethod(
        props.updateOrden.id,
        selectDiscountCode,
        auth.UserInfo.email
      )
    );
    setTimeout(function () {
      window.location.reload();
    }, 1500);
    setRefresh(true);
  };

  return (
    <>
      <YesNoModal
        header={headerDiscountCode}
        content={contentDiscountCode}
        visible={props.visibleDiscountCode}
        setVisible={props.setVisiblDiscountCode}
        yesHandler={changeDiscountCodeHandler}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default DiscountComponent;
